
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  
}



::-webkit-scrollbar {
  width: 10px;
  height: 10px;

}
::-webkit-scrollbar-track {
  background-color: #edebf1;
}
::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
  border-radius: 20px;
  border: 3px solid #edebf1;
}

.grid-2{
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  
}


.login-container {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-container h1 {
  text-align: center;
  margin-bottom: 20px;
}